import { useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import { Button, fonts } from '@lumoslabs/lumosity-storybook'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { springs } from '~/animation/SideNav.animation'
import ButtonLink from '~/components/ButtonLink'
import { showSideNavVar } from '~/graphql/reactive-vars/uiVar'
import useLocalDate from '~/hooks/useLocalDate'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import ChevronLeft from '~/images/SideNav/chevron_double_left.svg'
import Games from '~/images/SideNav/games.svg'
import Moon from '~/images/SideNav/moon.svg'
import Sun from '~/images/SideNav/sun.svg'
import You from '~/images/SideNav/you.svg'
import { breakpoints } from '~/styles/layout'
const { Subheading2Bold } = fonts

interface ShowSideNavProps {
  $showSideNav: boolean
}

const SideNav = (): JSX.Element => {
  const [animate, setAnimate] = useState(true)
  const { hour: localHour } = useLocalDate()
  const t = useTranslationForNamespace('common')
  const sideNavOpen = useReactiveVar(showSideNavVar)
  const toggleSideNav = () => showSideNavVar(!showSideNavVar())

  // title key and route for each menu determined here
  const sideNavButtons = [
    { title: 'today', route: '/' },
    { title: 'games', route: '/games' },
    { title: 'you', route: '/stats' },
  ]

  const router = useRouter()
  const tabActive = (route: string) => router.pathname === route

  useEffect(() => {
    const resizeHandler = () => {
      const hideSideNav = document.body.clientWidth < breakpoints.desktop
      const useFloatingNav = document.body.clientWidth > breakpoints.mobileLarge
      if (hideSideNav) {
        showSideNavVar(false)
      }
      setAnimate(useFloatingNav)
    }
    // call once to fit layout on mount
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  const openClose = useSpring(springs.openClose(sideNavOpen))

  const slideAndFlip = useSpring(springs.slideFlip(sideNavOpen))

  const getSvg = (hour: number, text: string, title: string) => {
    if (title === 'games') {
      return <Games title={text} />
    }
    if (title === 'you') {
      return <You title={text} />
    }
    if (title === 'today' && hour && (hour < 6 || hour >= 17)) {
      return <Moon title={text} />
    }
    if (title === 'today' && hour && hour >= 6 && hour < 17) {
      return <Sun title={text} />
    } else {
      return false
    }
  }

  return (
    <Container as={animated.div} style={animate ? openClose : undefined} $showSideNav={showSideNavVar()}>
      <NavButtonsContainer>
        {sideNavButtons.map((sideNavButton, i) => {
          const { title, route } = sideNavButton
          const text = t(`menu.${title}`)
          const leftBadge = <MenuIcons>{getSvg(localHour, text, title)}</MenuIcons>
          return (
            <ButtonContainer key={i + 1} $showSideNav={sideNavOpen}>
              <StyledButtonLink
                wide={true}
                href={route}
                kind='clean'
                leftBadge={leftBadge}
                tabActive={tabActive(route)}
                eventText={title}
              >
                <Text $showSideNav={sideNavOpen}>{text}</Text>
              </StyledButtonLink>
            </ButtonContainer>
          )
        })}
      </NavButtonsContainer>
      <SideNavControlButton onClick={toggleSideNav} kind='clean' wide={true}>
        <animated.div style={animate ? slideAndFlip : undefined}>
          <ChevronLeft title='Toggle Menu' height='40px' width='40px' />
        </animated.div>
      </SideNavControlButton>
    </Container>
  )
}
export default dynamic(() => Promise.resolve(SideNav), { ssr: false })

const Container = styled.div<ShowSideNavProps>`
  /* vertical nav */
  width: ${({ $showSideNav }) => ($showSideNav ? '260px' : '68px')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.coolGray92};
  background: ${({ theme }) => theme.colors.whiteBase};
  padding: 12px 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* bottom floating nav */
    justify-content: center;
    height: 64px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 2px 8px 2px rgba(14, 35, 55, 0.2);
    padding: 12px 0 5px 0;
  }
`

const NavButtonsContainer = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* Button row within floating nav */
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

const ButtonContainer = styled.div<ShowSideNavProps>`
  /* center icons within button */
  padding: 3px 0 5px 0;
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    /* divider between buttons */
    :not(:last-child) {
      position: relative;
      :before {
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.colors.coolGray92};
        content: '';
        position: absolute;
        bottom: 0;
        /* divider is partial and centered when nav is closed */
        width: ${({ $showSideNav }) => ($showSideNav ? '130%' : '56%')};
        left: ${({ $showSideNav }) => ($showSideNav ? '6%' : '25%')};
        transition: width 1s;
      }
    }
  }
  width: ${({ $showSideNav }) => ($showSideNav ? '260px' : '68px')};
  > a {
    margin-left: -5px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.desktop} {
    width: ${({ $showSideNav }) => ($showSideNav ? '200px' : '68px')};
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* Center icon and text for floating nav*/
    > a {
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        /* reset padding on badge icon */
        padding: 0;
      }
    }
  }
`

const StyledButtonLink = styled(ButtonLink)<{ tabActive: boolean }>`
  color: ${({ tabActive, theme }) => (tabActive ? theme.colors.orangeBase : theme.colors.coolGray44)};
`

const Text = styled(Subheading2Bold)<ShowSideNavProps>`
  text-transform: capitalize;
  display: ${({ $showSideNav }) => ($showSideNav ? 'inline' : 'none')};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* always display labels on small screen */
    display: inline-block;
    padding-top: 7px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
  }
`

const SideNavControlButton = styled(Button)`
  padding: 0 0 38px 0;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    /* On smallest screen size, no toggle button needed */
    /* b/c nav is always visible */
    display: none;
  }
`

const MenuIcons = styled.span`
  font-size: 26px;
`
