import { useEffect, useMemo, useState } from 'react'

import useTranslation from 'next-translate/useTranslation'

const useLocale = () => {
  const [navLanguage, setNavLanguage] = useState('en-us')
  const [navLanguageSet, setNavLanguageSet] = useState(false)

  useEffect(() => {
    setNavLanguage(navigator?.language)
    setNavLanguageSet(true)
  }, [])

  const { lang } = useTranslation()

  // Check if navLanguage is a supported language
  const isSupported = useMemo(() => /^en|fr|pt|de|es|ja|ko/.test(navLanguage), [navLanguage])

  // If the user's browser is on a supported language and that's the language the site is set to,
  // use the browser language which includes the region, otherwise just use the language the site is set to
  const locale: string = isSupported && navLanguage.startsWith(lang) ? navLanguage : lang

  // To set the site language when getting redirected from LumAuth
  // we need to get the first 2 characters of the browser language, if it's a language we support, otherwise default to English
  const browserLanguagePrefix = isSupported ? navLanguage.slice(0, 2) : 'en'
  const nonEnglishLanguagePrefix = browserLanguagePrefix === 'en' ? '' : browserLanguagePrefix

  return { locale, navLanguageSet, nonEnglishLanguagePrefix }
}

export default useLocale
