import { useEffect, useState } from 'react'

const useIsMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  useEffect(() => {
    const { isMobile, isTablet } = require('react-device-detect')
    setIsMobileDevice(isMobileDevice || isMobile || isTablet)
  }, [isMobileDevice])
  return isMobileDevice
}

export default useIsMobileDevice
