import { fonts } from '@lumoslabs/lumosity-storybook'
import getConfig from 'next/config'
import styled from 'styled-components'

// FooterLinks keys must match the keys in en.json to display the link label
import { FOOTER_LINKS } from '~/constants'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { MicroText1, MicroText2BoldCaps } = fonts

const { publicRuntimeConfig } = getConfig()

const isResearch = publicRuntimeConfig.researchMode

const Footer: React.FC = () => {
  const t = useTranslationForNamespace('common')
  if (isResearch) {
    return (
      <FooterContainer>
        <LinkContainer>
          {t('footer.needHelp')}{' '}
          <FormattedLink href='mailto:brightmindsubjects@gmail.com'>brightmindsubjects@gmail.com</FormattedLink>.
        </LinkContainer>
      </FooterContainer>
    )
  }
  return (
    <FooterContainer>
      <Copyright>{t('footer.copyright')}</Copyright>
      <Links>
        {Object.entries(FOOTER_LINKS).map(([label, link]) => (
          <LinkContainer key={label}>
            <a href={link}>
              <LinkText>{t('footer.' + label)}</LinkText>
            </a>
          </LinkContainer>
        ))}
      </Links>
    </FooterContainer>
  )
}

const Copyright = styled(MicroText2BoldCaps)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    padding: 15px;
  }
  color: ${({ theme }) => theme.colors.coolGray44};
  padding-top: 5px;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
`

const LinkContainer = styled.span`
  padding: 0 16px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    text-align: center;
  }
`

export const FormattedLink = styled.a`
  color: ${({ theme }) => theme.colors.inkBase};
  text-decoration: underline ${({ theme }) => theme.colors.inkBase};
  &:hover {
    text-underline-offset: 5px;
  }
`

const Links = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

const LinkText = styled(MicroText1)`
  color: ${({ theme }) => theme.colors.coolGray44};
`

export default Footer
