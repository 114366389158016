import styled from 'styled-components'

const BasicHeader = () => {
  return <SimpleTopNav />
}

const SimpleTopNav = styled.div`
  padding: 0px 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  background-color: ${({ theme }) => theme.colors.inkBase};
`

export default BasicHeader
