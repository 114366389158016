
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react'

import { GlobalStyle } from '@lumoslabs/lumosity-storybook'
import type { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { CookiesProvider } from 'react-cookie'
import AuthProvider from 'src/components/auth/AuthProvider'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { ModalProvider } from 'styled-react-modal'

import AppLayout from '~/components/layout/AppLayout'
import EmptyLayout from '~/components/layout/EmptyLayout'
import StyledModalBackground from '~/components/modals/StyledModalBackground'
import MobileDevicesInterstitialResearch from '~/components/ResearchOnly/MobileDevicesInterstitialResearch'
import Rollbar from '~/components/Rollbar'
import RouteGuard from '~/components/RouteGuard'
import SnackbarProvider from '~/components/snackbars/SnackbarProvider'
import AnalyticsProvider from '~/context/AnalyticsContext'
import useIsMobileDevice from '~/hooks/useIsMobileDevice'
import theme from '~/styles/theme'

const LAYOUTS: Record<string, React.FunctionComponent> = {
  game: EmptyLayout as React.FunctionComponent,
  onboard: EmptyLayout as React.FunctionComponent,
  'fit-test': EmptyLayout as React.FunctionComponent,
  workout: EmptyLayout as React.FunctionComponent,
  dev: EmptyLayout as React.FunctionComponent,
  author: EmptyLayout as React.FunctionComponent,
  login: EmptyLayout as React.FunctionComponent,
  logout: EmptyLayout as React.FunctionComponent,
  404: EmptyLayout as React.FunctionComponent,
  settings: EmptyLayout as React.FunctionComponent,
}

const { publicRuntimeConfig } = getConfig()

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const isResearch = publicRuntimeConfig.researchMode
  const router = useRouter()
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_, page] = router.pathname.split('/')
  const Layout = isResearch ? (EmptyLayout as React.FunctionComponent) : LAYOUTS[page] || AppLayout
  const isMobileDevice = useIsMobileDevice()

  return (
    <>
      <Normalize />
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Rollbar />
      </Head>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <AuthProvider>
            <SnackbarProvider>
              <RouteGuard>
                <AnalyticsProvider>
                  <GlobalStyle />
                  <ModalProvider backgroundComponent={StyledModalBackground}>
                    <Layout>
                      {isMobileDevice && isResearch ? (
                        <MobileDevicesInterstitialResearch />
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </Layout>
                  </ModalProvider>
                </AnalyticsProvider>
              </RouteGuard>
            </SnackbarProvider>
          </AuthProvider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  )
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  