import useTranslation from 'next-translate/useTranslation'

export const useTranslationForNamespace = (namespace: string) => {
  const { t } = useTranslation('common')
  /*
  If the key includes `:` this means we are specifying a different namespace
  then the one passed in the initialization of useTranslationForNamespace(),
  so don't append the namespace, otherwise append it
  */
  const translate = (baseKey: string, vars = {}, config = { fallback: '' }) => {
    const hasColon = baseKey.includes(':')
    const baseKeyString = hasColon ? baseKey.replace(':', '.') : baseKey
    const key = hasColon ? baseKeyString : `${namespace}.${baseKey}`
    // If no fallback is provided, add 'string' as a fallback in case we put the string and context in an object in the locale file
    if (config.fallback === '') {
      config['fallback'] = `${key}.string`
    }

    return t(key, vars, config)
  }

  return translate
}
