import { useState } from 'react'

import { useCookies } from 'react-cookie'

import { AuthContext } from '~/context/AuthContext'
import usePersistedClientConfig from '~/hooks/usePersistedClientValues'

interface AuthProviderProps {
  children: React.ReactNode
  hasPremium?: boolean
  isFreeTrialEligible?: boolean
  thirdPartyCookiesSupportedOverride?: boolean
}

/**
 * Use AuthProvider to handle auth state
 */
const AuthProvider = ({ children, hasPremium = true, isFreeTrialEligible = false }: AuthProviderProps) => {
  const [cookie] = useCookies(['lumosUser'])
  const thirdPartyCookiesSupported = false
  const [hasAccountStateOverride, setHasAccountStateOverride] = useState<boolean>(false)
  const [_hasPremium, setHasPremium] = useState<boolean>(hasPremium)
  const [_isFreeTrialEligible, setIsFreeTrialEligible] = useState<boolean>(isFreeTrialEligible)

  // read local client values and set client state
  usePersistedClientConfig(cookie)

  return (
    <AuthContext.Provider
      value={{
        hasAccountStateOverride,
        setHasAccountStateOverride,
        hasPremium: _hasPremium,
        setHasPremium,
        isFreeTrialEligible: _isFreeTrialEligible,
        setIsFreeTrialEligible,
        thirdPartyCookiesSupported,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
