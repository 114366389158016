const translationMessageStyles = [
  'color: #0e2337',
  'font-size: 12px',
  'border: 1px solid black',
  'border-radius: 5px',
  'padding: 3px 5px',
  'background: #A9A9A9',
].join(';')

const getTranslationErrorMessage = (namespace, i18nKey) => {
  if (!i18nKey) {
    // lacking key implies no namespace was provided
    return `[next-translate] The key "${namespace}" lacks a namespace. Please check your translation lookup.`
  }
  return `[next-translate] ${namespace}:${i18nKey} is missing in current namespace configuration. Try adding "${i18nKey}" to the namespace "${namespace}".`
}

const logToRollbar = (message) => {
  if (typeof window !== 'undefined' && message) {
    window?.Rollbar?.error?.(message, (rollbarError) => {
      if (rollbarError) {
        console.error('Rollbar error reporting failed:')
        console.error(rollbarError)
        return
      }
      console.log('Reported error to Rollbar')
    })
  }
}

const missingKeys = ({ namespace, i18nKey }) => {
  const environment = process.env.NODE_ENV
  const hazard = String.fromCodePoint('0x1F6A7')
  const globe = String.fromCodePoint('0x1F30D')
  const arrow = String.fromCodePoint('0x27A1')
  const messagePrefix = `${hazard} ${globe} Translation Error ${arrow}`
  const message = getTranslationErrorMessage(namespace, i18nKey)

  const logError = console.error('%c%s', translationMessageStyles, messagePrefix, message)

  if (environment === 'production') {
    logToRollbar(message)
  } else {
    logError
  }
}

module.exports = { missingKeys, getTranslationErrorMessage, translationMessageStyles }
