import { keyBy } from 'lodash'

import { AreaSlug } from '~/constants'

const GamesLibrary: Array<GameConfig> = [
  {
    slug: 'brain-shift',
    id: 'de23e83f-59ec-4338-a727-f88331cdb819',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Brain-Shift.svg',
    heroImage: '/assets/images/games/brain-shift-web-hero.svg',
    assetsPath: 'BrainShift_CC/web/3.0.2_2022_06_08/c370fd2c5eef171d77b4cd7be6dc886563f0053e/558616247/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'disillusion',
    id: 'cb129d45-fac4-4fe5-bcd1-75d8b9ccc67d',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Disillusion.svg',
    heroImage: '/assets/images/games/disillusion-web-hero.svg',
    assetsPath: 'Disillusion_CC/web/3.0.5_2022_06_01/e6cc45072ab5e98e7431a0d3e365935781b9d612/553167559/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'eagle-eye',
    id: '93ba9128-2e52-43c9-aec8-5d4c1c2af287',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Eagle-Eye.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'EagleEye_CC/web/3.0.9_2022_08_10/c77c035180b73cd12b3cc316530b5974afb322da/608960991/release',
    bestStatLabelKey: 'unknown',
    isStatLabelFirst: false,
  },
  {
    slug: 'ebb-and-flow',
    id: 'e4250024-805d-43d3-93f0-9352b1621117',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Ebb-And-Flow.svg',
    heroImage: '/assets/images/games/ebb-and-flow-web-hero.svg',
    assetsPath: 'EbbAndFlow_CC/master/75878eaa983acbffccc565fa8363a41f55a1e74c/183325132/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'lost-in-migration',
    id: '11700bab-fbed-4d0c-8d05-42b696ec133e',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Lost-In-Migration.svg',
    heroImage: '/assets/images/games/lost-in-migration-web-hero.svg',
    assetsPath: 'LostInMigration_CC/web/3.0.6_2022_06_01/32bb4a7b7ea0b9f5a145097e2904d5d3d5daf075/553166193/release',
    bestStatLabelKey: 'flocks',
    isStatLabelFirst: false,
  },
  {
    slug: 'masterpiece',
    id: '99ee8966-a46f-4b54-a352-766c3189573b',
    areaSlug: AreaSlug['problem-solving'],
    gamesPageImage: '/assets/images/games/Game-Icon-Masterpiece.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'Masterpiece_CC/master/b76b1feaf33bcc49b48997c75ecfdb5cf9e7393f/224407235/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'memory-match',
    id: 'abe34230-e1b4-446e-8315-0b4e5cf8c3e9',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Memory-Match.svg',
    heroImage: '/assets/images/games/memory-match-web-hero.svg',
    assetsPath: 'MemoryMatch_CC/web/3.0.5_2022_10_21/2524afc60bd37e09862f974b2d038d751c723421/673125911/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
  },
  {
    slug: 'memory-matrix',
    id: '3de8b1a9-1309-4a2c-97f5-be0af1aba8a4',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Memory-Matrix.svg',
    heroImage: '/assets/images/games/memory-matrix-web-hero.svg',
    assetsPath: 'MemoryMatrix_CC/web/4.0.4_2021_08_02/ccbbf2314514abbbc862ae87017a392728510ab9/346582755/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
  },
  {
    slug: 'memory-serves',
    id: 'c03300cb-1ed8-4e10-92b4-93d8f332ad98',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Memory-Serves.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'MemoryServes_CC/web/1.0.6_2021_09_01/cc972f77ac38c7e225ffa1aeb0fb9e8bc0a2bfdd/363370391/release',
    bestStatLabelKey: 'correct-deliveries',
    isStatLabelFirst: false,
  },
  {
    slug: 'penguin-pursuit',
    id: '49298e56-6d41-43f2-91aa-4b78316d91bd',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-Penguin-Pursuit.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'PenguinPursuit_CC/web/3.0.5_2022_10_12/3fde4d8100c3ec72eaba7e7567136b57b7f72629/664635214/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
  },
  {
    slug: 'pet-detective',
    id: '6b3d672c-2b96-42a3-8893-9693f39f5c74',
    areaSlug: AreaSlug['problem-solving'],
    gamesPageImage: '/assets/images/games/Game-Icon-Pet-Detective.svg',
    heroImage: '/assets/images/games/pet-detective-web-hero.svg',
    assetsPath: 'PetDetective_CC/web/3.1.7_2022_05_25/029ca9f353f8867d3dce43ca9ba3302cefc292b0/547856056/release',
    bestStatLabelKey: 'pets-returned',
    isStatLabelFirst: false,
  },
  {
    slug: 'pinball-recall',
    id: '3c59f4c5-cdc8-487b-a20b-99ae829829ff',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Pinball-Recall.svg',
    heroImage: '/assets/images/games/pinball-recall-web-hero.svg',
    assetsPath: 'PinballRecall_CC/web/3.0.4_2022_05_25/57863c35552f695546b092bd4ffa8caea9dc7717/547853738/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
  },
  {
    slug: 'raindrops',
    id: '22d72145-6f5c-4f4c-9a30-61030d80e10b',
    areaSlug: AreaSlug.math,
    gamesPageImage: '/assets/images/games/Game-Icon-Raindrops.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'Raindrops_CC/web/3.1.4_2021_10_27/4af13177454056c0452a9474d89a961f7a3e3ce0/396650873/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'river-ranger',
    id: '82e3b087-1d2c-469e-a9ad-eb317a30a8da',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-River-Ranger.svg',
    heroImage: '/assets/images/games/river-ranger-web-hero.svg',
    assetsPath: 'RiverRanger_CC/web/3.1.4_2022_01_05/d7f7e30b87e8b031bd2654d297bb3b621a67e476/441904900/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
  },
  {
    slug: 'rotation-matrix',
    id: '716c8c76-9664-459e-a11a-8b02017507ba',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Rotation-Matrix.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'RotationMatrix_CC/web/3.0.5_2021_07_12/34d8a7c51f011e220ad33f771e2403435f564b1a/335481555/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
  },
  {
    slug: 'skyrise',
    id: '1b252c4b-a603-4276-ac01-bfdf962bac6f',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Skyrise.svg',
    heroImage: '/assets/images/games/skyrise-web-hero.svg',
    assetsPath: 'ObservationTower_CC/web/1.3.0_2023_09_08/ab7d569025be60e9062fc4f022fbbd9ad3134e68/997884407/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
  },
  {
    slug: 'spatial-speed-match',
    id: '76a8370f-235b-41cc-bfd8-f0def48a3a0b',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-Spatial-Speed-Match.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'SpatialSpeedMatch_CC/web/3.2.3_2021_05_19/07840d64a12198464b6ed98eb73c676503ea3642/305814972/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'speed-pack',
    id: 'ddfbbd8a-d832-4f04-b980-eec4e13cfc86',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-Speed-Pack.svg',
    heroImage: '/assets/images/games/hero/speed-pack-web-hero.svg',
    assetsPath: 'SpeedPack_CC/web/3.0.6_2021_09_15/d78f3fe95f90e27e0328f1535a93162aade9755e/371167810/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'star-search',
    id: 'a47fbba8-111e-4720-887f-2f9efe09fd05',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Star-Search.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'StarSearch_CC/web/3.0.5_2022_05_25/e6009b64a2c933240085e34624b50fecb861e381/547850780/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
  },
  {
    slug: 'tidal-treasures',
    id: 'cfff978a-5bac-4d60-8fe6-4425e25be224',
    areaSlug: AreaSlug.memory,
    gamesPageImage: '/assets/images/games/Game-Icon-Tidal-Treasures.svg',
    heroImage: '/assets/images/games/tidal-treasures-web-hero.svg',
    assetsPath: 'TidalTreasures_CC/web/3.0.4_2021_09_22/865eeb3c1d8caaab17642a754f4300689bffd7fe/374982697/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'train-of-thought',
    id: '3564ab29-642f-4acc-9ee2-c7e3ac552d33',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Train-Of-Thought.svg',
    heroImage: '/assets/images/games/train-of-thought-web-hero.svg',
    assetsPath: 'TrainOfThought_CC/web/3.0.9_2021_09_15/9fc82dd6516dec9fd8c8cd8ae13b2118bd657d06/371167854/release',
    bestStatLabelKey: 'trains',
    isStatLabelFirst: false,
  },
  {
    slug: 'trouble-brewing',
    id: '17b5f376-5ac4-44e7-8e80-508201e62435',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Trouble-Brewing.svg',
    heroImage: '/assets/images/games/trouble-brewing-web-hero.svg',
    assetsPath: 'TroubleBrewing_CC/web/3.1.3_2021_12_07/8cff16085e23d47a0f6a32a62ae7ffb96695a21e/424297560/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'word-bubbles',
    id: 'd0c8791c-f093-472b-9adf-fbf72f4e9db4',
    areaSlug: AreaSlug.language,
    gamesPageImage: '/assets/images/games/Game-Icon-Word-Bubbles.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'WordBubbles3_CC/master/4c728b9c28ea1321c22c8b413afb581e296efeb1/208881242/release',
    bestStatLabelKey: 'words',
    isStatLabelFirst: false,
  },
]

export const GamesLibraryDictionary = keyBy(GamesLibrary, 'slug')

export const allGameSlugs: GameSlug[] = GamesLibrary.map((game) => game.slug)

// Add any games that shouldn't be included in workouts here
export const unplayableGameSlugs: GameSlug[] = []

// Use this list for workout queries until the issue above is resolved
export const playableGameSlugs: GameSlug[] = allGameSlugs.filter((slug) => !unplayableGameSlugs.includes(slug))
/**********************************************************************/

export default GamesLibrary
