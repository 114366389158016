import { fonts } from '@lumoslabs/lumosity-storybook'
import Head from 'next/head'
import styled from 'styled-components'

import BasicHeader from '~/components/BasicHeader'
import PageContent from '~/components/ui/PageContent'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { Heading3, Subheading2 } = fonts

const MobileDevicesInterstitialResearch = (): JSX.Element => {
  const t = useTranslationForNamespace('common')

  return (
    <>
      <Head>
        <title>{t('interstitials.mobileDevicesResearch.title')}</title>
      </Head>
      <BasicHeader />
      <PageContent>
        <PageContent.Header>
          <Heading3 as='h3'>{t('interstitials.mobileDevicesResearch.header')}</Heading3>
        </PageContent.Header>
        <PageContent.Main>
          <CaptionContainer>
            <Subheading2>{t('interstitials.mobileDevicesResearch.body')}</Subheading2>
          </CaptionContainer>
        </PageContent.Main>
      </PageContent>
    </>
  )
}

const CaptionContainer = styled.div`
  margin-top: 16px;
`

export default MobileDevicesInterstitialResearch
