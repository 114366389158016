import { createContext } from 'react'

interface AuthContextValues {
  hasAccountStateOverride: boolean
  setHasAccountStateOverride: (hasAccountStateOverride: boolean) => void
  hasPremium: boolean
  setHasPremium: (hasPremium: boolean) => void
  isFreeTrialEligible: boolean
  setIsFreeTrialEligible: (isFreeTrialEligible: boolean) => void
  thirdPartyCookiesSupported: boolean
}

export const AuthContext = createContext<AuthContextValues>({
  hasAccountStateOverride: false,
  setHasAccountStateOverride: () => null,
  hasPremium: true,
  setHasPremium: () => null,
  isFreeTrialEligible: false,
  setIsFreeTrialEligible: () => null,
  thirdPartyCookiesSupported: false,
})
