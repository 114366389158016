import { SnackbarProvider as OriginalSnackbarProvider } from 'notistack'
import styled from 'styled-components'

import LeafyBackgroundImage from '~/images/Snackbars/leafy_background.svg'

interface SnackbarProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <OriginalSnackbarProvider maxSnack={1}>
      {/* Load image for use by snackbar components via <use> tag */}
      <LeafyBackground />
      {children}
    </OriginalSnackbarProvider>
  )
}

export default SnackbarProvider

const LeafyBackground = styled(LeafyBackgroundImage).attrs({
  title: 'leafy-background',
  id: 'leafy-background',
})`
  /* Hide image */
  width: 0;
  height: 0;
  position: absolute;
`
