const { missingKeys } = require('./src/utils/loggerUtils.js')
/*
- Strings go in common. 
- Nest strings in the section named for the page they are used on, unless they are used on multiple pages, 
in which case nest them under the common section within the common file.
*/
module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  defaultNS: 'common',
  pages: {
    '*': ['common'],
  },
  logger: missingKeys,
}
