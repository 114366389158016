import React, { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import amplitude from 'amplitude-js'
import getConfig from 'next/config'

import { currentUserVar } from '~/graphql/reactive-vars/userVar'

const { publicRuntimeConfig } = getConfig()

export enum AnalyticsEvents {
  ScreenView = 'Page View',
  CTAClick = 'CTA Click',
  GameStart = 'Game Start',
  GameAbort = 'Game Abort',
  GameFinish = 'Game Finish',
  WorkoutGameSwap = 'Workout Game Swap',
}

type OptionalAmplitude = amplitude.AmplitudeClient | null

interface AnalyticsContextStorage {
  client: OptionalAmplitude
  currentScreen: string
  setCurrentScreen: (name: string) => unknown
}

export const AnalyticsContext = React.createContext<AnalyticsContextStorage>({
  client: null,
  currentScreen: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentScreen: (name: string) => null,
})

const AnalyticsProvider: React.FunctionComponent = ({ children }) => {
  const [client, setClient] = React.useState<OptionalAmplitude>(null)
  const [currentScreen, setCurrentScreen] = React.useState<string>('')
  const currentUser = useReactiveVar(currentUserVar)

  useEffect(() => {
    if (publicRuntimeConfig.analytics?.amplitude?.apikey) {
      const instance = amplitude.getInstance()
      instance.init(publicRuntimeConfig.analytics?.amplitude?.apikey)
      setClient(instance)
    }
  }, [])

  useEffect(() => {
    client?.setUserId(currentUser?.id || null)
  }, [currentUser, client])

  return (
    <AnalyticsContext.Provider
      value={{
        client,
        currentScreen,
        setCurrentScreen,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
